import React from "react";
import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { SubTitle } from "../../Atoms/SubTitle";
import { LineChart } from "@mui/x-charts";
import { ILineChartprops, useDataTendencia } from "./useData";
import { maskMoney } from "../../../utils/masks";
import { GraphColors } from "../../../utils/graphCorlors";

interface ILegend {
  title: string;
  color: string;
}
const TrendChart = ({
  title,
  chartData,
  legends,
}: {
  title: string;
  chartData: ILineChartprops;
  legends?: ILegend[];
}) => (
  <>
    <SubTitle>{title}</SubTitle>
    <S.Card>
      <LineChart
        margin={{ bottom: 70 }}
        height={300}
        width={760}
        xAxis={[{ scaleType: "band", data: chartData?.xAxis || [] }]}
        series={chartData?.series || []}
        slotProps={{
          legend: { hidden: true },
        }}
      />

      <S.WrapperLegends>
        {legends?.map((e, i) => (
          <S.Legend key={i}>
            <S.BoxColor color={e?.color} />
            <S.TextLegend>{e?.title}</S.TextLegend>
          </S.Legend>
        ))}
      </S.WrapperLegends>
    </S.Card>
  </>
);

export const TendenciesTemplate = () => {
  const {
    data,
    tendenciasGeral,
    tendenciasTipoServico,
    tendenciasUnidade,
    tendenciasAtendimento,
    tendenciasFaturamento,
  } = useDataTendencia();

  return (
    <LayoutTemplate title="Dashboard de Tendência">
      <S.Container>
        <TrendChart
          title="Tendência Geral"
          chartData={tendenciasGeral}
          legends={[
            {
              title: "Geral",
              color: GraphColors[data?.empresa],
            },
          ]}
        />
        <TrendChart
          title="Tendência por Tipo de Serviço"
          chartData={tendenciasTipoServico}
          legends={tendenciasTipoServico?.series?.map((e) => ({
            title: e.label,
            color: e.color,
          }))}
        />
        <TrendChart
          title="Tendência por Tipo de Atendimento"
          chartData={tendenciasAtendimento}
          legends={tendenciasAtendimento?.series?.map((e) => ({
            title: e.label,
            color: e.color,
          }))}
        />
        <TrendChart
          title="Tendência por Unidade"
          chartData={tendenciasUnidade}
          legends={tendenciasUnidade?.series?.map((e) => ({
            title: e.label,
            color: e.color,
          }))}
        />
        <TrendChart
          title="Tendência de Faturamento"
          chartData={tendenciasFaturamento}
        />
      </S.Container>

      <S.TableTitle>Desempenho em Relação a Meta</S.TableTitle>

      <S.Table>
        <S.TableHead>
          <tr>
            <td colSpan={7}>Loja</td>
          </tr>
          <tr>
            <td>Unidade</td>
            <td>Meta</td>
            <td>Realizado</td>
            <td>Média/dia</td>
            <td>Necessidade/dia</td>
            <td>Projeção</td>
          </tr>
        </S.TableHead>
        <S.TableBody>
          {data?.unidades?.length > 0 &&
            data?.unidades?.map((empresa) => (
              <tr key={Math.random()}>
                <td>{`${empresa?.cidade} - ${empresa.uf}`}</td>
                <td>{empresa?.metaQtdLoja}</td>
                <td>{empresa?.qtdTotalLoja}</td>
                <td>{empresa?.qtdMediaLoja}</td>
                <td>{empresa?.qtdMediaNecessariaLoja}</td>
                <td>{empresa?.qtdMediaProjecaoLoja}</td>
              </tr>
            ))}
        </S.TableBody>
      </S.Table>

      <S.Table>
        <S.TableHead>
          <tr>
            <td colSpan={7}>Delivery</td>
          </tr>
          <tr>
            <td>Unidade</td>
            <td>Meta</td>
            <td>Realizado</td>
            <td>Média/dia</td>
            <td>Necessidade/dia</td>
            <td>Projeção</td>
          </tr>
        </S.TableHead>
        <S.TableBody>
          {data?.unidades?.length > 0 &&
            data?.unidades?.map((empresa) => (
              <tr key={Math.random()}>
                <td>{`${empresa?.cidade} - ${empresa.uf}`}</td>
                <td>{empresa?.metaQtdDelivery}</td>
                <td>{empresa?.qtdTotalDelivery}</td>
                <td>{empresa?.qtdMediaDelivery}</td>
                <td>{empresa?.qtdMediaNecessariaDelivery}</td>
                <td>{empresa?.qtdMediaProjecaoDelivery}</td>
              </tr>
            ))}
        </S.TableBody>
      </S.Table>
    </LayoutTemplate>
  );
};
