import styled from "styled-components";

export const MenuWrapper = styled.nav`
  background-color: #1d1d1b;
  padding: 16px;
  overflow: hidden;

  &[data-open="true"] {
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;

    width: 300px;
  }

  &[data-open="false"] {
    -webkit-transition: width 0.3s ease-in-out;
    -moz-transition: width 0.3s ease-in-out;
    -o-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    width: 52px;
  }
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  > img {
    display: block;
    cursor: pointer;
    width: 15px;
    transform: rotate(90deg);
  }
`;

export const WrapperProfile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  flex: 1;

  > img {
    display: block;
    width: 54px;
    height: 54px;
  }

  &[data-open="false"] {
    opacity: 0;
    width: 0;
    overflow: hidden;
  }
`;

export const WrapperProfileText = styled.div`
  font-size: 12px;
  display: flex;
  color: #878787;
  flex-direction: column;
  gap: 4px;
  white-space: nowrap;

  color: #878787;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;

  > p > span {
    color: #e84e1b;
  }

  > button {
    all: unset;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: underline;

    > img {
      display: block;
    }
  }
`;

export const MenuItems = styled.ul`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const MenuItem = styled.li`
  > a {
    display: flex;
    gap: 16px;
    align-items: center;
    white-space: nowrap;

    color: #878787;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;

    > img {
      width: 24px;
      height: 24px;
      display: block;
    }
  }

  > a.active {
    color: #fff;

    > img {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(4368%) hue-rotate(323deg) brightness(117%) contrast(113%);
    }
  }
`;
