export const generateUniqueHexColor = (() => {
  const generatedColors = new Set<string>();

  return (): string => {
    let color: string;

    do {
      // Gera uma cor hexadecimal aleatória
      color = `#${Math.floor(Math.random() * 0xffffff)
        .toString(16)
        .padStart(6, "0")}`;
    } while (generatedColors.has(color));

    // Armazena a cor gerada para evitar repetição
    generatedColors.add(color);

    return color;
  };
})();
