import styled from "styled-components";

export const Container = styled.div`
  > h2 {
    text-align: start;
  }
`;

export const Card = styled.div`
  width: fit-content;
  height: fit-content;
  border-radius: 0.25rem;
  background: #f6f6f6;
  box-shadow: 4px 4px 6px 0px rgba(0, 0, 0, 0.2);
  margin-bottom: 3rem;
  padding: 1rem;
`;

export const TableTitle = styled.h3`
  margin-bottom: 2rem;
`;

export const Table = styled.table`
  max-width: 52rem;
  width: 100%;
  margin-bottom: 2rem;

  &,
  th,
  td {
    border-collapse: collapse;
    border: 0.5px solid #cbcbcb;
  }
`;

export const TableHead = styled.thead`
  background: #1d1d1b;
  height: fit-content;

  tr:first-child {
    background-color: #e84e1b;
    > td {
      padding: 10px;
    }
  }

  tr:nth-child(2) {
    > td {
      padding: 16px;
    }
  }

  td {
    padding: 0 8px;
    color: #fff;
    text-align: start;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
`;

export const TableBody = styled.tbody`
  background: #f6f6f6;

  > tr {
    height: 3rem;
  }

  td {
    padding: 0 8px;
    color: #a3a3a3;
    text-align: start;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.25rem;
  }
`;

export const WrapperLegends = styled.div`
  display: flex;
  width: fit-content;
  gap: 16px;
  margin-left: auto;
`;

export const Legend = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
`;

export const TextLegend = styled.p``;

export const BoxColor = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: ${(props) => props.color};
`;
