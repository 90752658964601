import { useCallback, useEffect, useState } from "react";
import { useContextSite } from "../../../context/Context";
import { Gerenciamento } from "../../../services/Gerenciamento";
import { toast } from "react-toastify";
import { GraphColors } from "../../../utils/graphCorlors";
import { ITendenciaDTO } from "../../../types/tendencia";
import { maskMoney } from "../../../utils/masks";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { MakeOptional } from "@mui/x-charts/models/helpers";
import { LineSeriesType } from "@mui/x-charts";
import { generateUniqueHexColor } from "../../../utils/generateUniqueHexColor";

export interface ILineChartprops {
  xAxis: string[];
  series: MakeOptional<LineSeriesType, "type">[];
}

export const useDataTendencia = () => {
  const { empresa } = useParams();
  const { setIsLoad } = useContextSite();

  const [data, setData] = useState<ITendenciaDTO>({} as ITendenciaDTO);
  const [tendenciasGeral, setTendenciasGeral] = useState<ILineChartprops>(
    {} as ILineChartprops
  );

  const [tendenciasTipoServico, setTendenciasTipoServicos] =
    useState<ILineChartprops>({} as ILineChartprops);

  const [tendenciasUnidade, setTendenciasUnidade] = useState<ILineChartprops>(
    {} as ILineChartprops
  );
  const [tendenciasAtendimento, setTendenciasAtendimento] =
    useState<ILineChartprops>({} as ILineChartprops);

  const [tendenciasFaturamento, setTendenciasFaturamento] =
    useState<ILineChartprops>({} as ILineChartprops);

  const getTendencias = useCallback(() => {
    setIsLoad(true);

    function formatDataTendenciasGeral(data: ITendenciaDTO): ILineChartprops {
      const formattedXAxis = data.geral.map((e) =>
        dayjs(e.data).format("DD/MM/YYYY")
      );

      const formattedSeries = [
        {
          data: data.geral.map((e) => e.qtdTotal),
          label: "Total",
          color: GraphColors[data?.empresa],
          // valueFormatter: (value: number) => maskMoney(value),
        },
      ];

      return {
        xAxis: formattedXAxis,
        series: formattedSeries,
      };
    }

    function formatDataTendenciasFaturamento(
      data: ITendenciaDTO
    ): ILineChartprops {
      const formattedXAxis = data.geral.map((e) =>
        dayjs(e.data).format("DD/MM/YYYY")
      );

      const formattedSeries = [
        {
          data: data.geral.map((e) => e.valorTotal),
          label: "Valor Total",
          color: GraphColors[data?.empresa],
          valueFormatter: (value: number) => maskMoney(value),
        },
      ];

      return {
        xAxis: formattedXAxis,
        series: formattedSeries,
      };
    }

    function formatDataTendenciasServicos(
      data: ITendenciaDTO
    ): ILineChartprops {
      const formattedXAxis = data.geral.map((e) =>
        dayjs(e.data).format("DD/MM/YYYY")
      );

      const formattedSeries = [
        {
          data: data.geral.map((e) => e.qtdPrimeiroEmplacamento),
          label: "Primeiro Emplacamento",
          color: GraphColors[data?.empresa],
        },
        {
          data: data.geral.map((e) => e.qtdTransferencia),
          color: "#1D1D1B",
          label: "Transferência",
        },
      ];

      return {
        xAxis: formattedXAxis,
        series: formattedSeries,
      };
    }

    function formatDataTendenciasAtendimento(
      data: ITendenciaDTO
    ): ILineChartprops {
      const formattedXAxis = data.geral.map((e) =>
        dayjs(e.data).format("DD/MM/YYYY")
      );

      const formattedSeries = [
        {
          data: data.geral.map((e) => e.qtdLoja),
          label: "Loja",
          color: GraphColors[data?.empresa],
        },
        {
          data: data.geral.map((e) => e.qtdDelivery),
          color: "#1D1D1B",
          label: "Delivery",
        },
        // {
        //   data: data.geral.map((e) => e.qtdItinerante),
        //   color: generateUniqueHexColor(),
        //   label: "Itinerante",
        // },
      ];

      return {
        xAxis: formattedXAxis,
        series: formattedSeries,
      };
    }

    function formatDataTendenciasUnidade(data: ITendenciaDTO): ILineChartprops {
      const DIAS = data.unidades.flatMap((e) => e.dias);
      const formattedXAxis = DIAS.map((e) =>
        dayjs(e.data).format("DD/MM/YYYY")
      );

      const formattedSeries = data.unidades.map((unidade) => ({
        data: unidade.dias.map((dia) => dia.qtdTotal),
        label: unidade.cidade,
        color: generateUniqueHexColor(),
      }));

      return {
        xAxis: formattedXAxis,
        series: formattedSeries,
      };
    }

    Gerenciamento.tendencia({ empresa })
      .then(({ data }) => {
        if (data?.length > 0) {
          setData(data[0]);

          const geral = formatDataTendenciasGeral(data[0]);
          const faturamento = formatDataTendenciasFaturamento(data[0]);
          const servicos = formatDataTendenciasServicos(data[0]);
          const atendimento = formatDataTendenciasAtendimento(data[0]);
          const unidade = formatDataTendenciasUnidade(data[0]);

          setTendenciasGeral(geral);
          setTendenciasTipoServicos(servicos);
          setTendenciasAtendimento(atendimento);
          setTendenciasUnidade(unidade);
          setTendenciasFaturamento(faturamento);

          return;
        }

        setTendenciasGeral({} as ILineChartprops);
        setTendenciasTipoServicos({} as ILineChartprops);
        setTendenciasAtendimento({} as ILineChartprops);
        setTendenciasUnidade({} as ILineChartprops);
        setTendenciasFaturamento({} as ILineChartprops);
        setData({} as ITendenciaDTO);
      })
      .catch(
        ({
          response: {
            data: { mensagem },
          },
        }) => {
          toast.error(mensagem);
        }
      )
      .finally(() => {
        setIsLoad(false);
      });
  }, [empresa]);

  useEffect(() => {
    if (empresa) {
      getTendencias();
    }
  }, [getTendencias, empresa]);

  return {
    data,
    tendenciasGeral,
    tendenciasTipoServico,
    tendenciasAtendimento,
    tendenciasUnidade,
    tendenciasFaturamento,
  };
};
